import styled from '@emotion/styled'
import React from 'react'

const Wrapper = styled.p`
  margin: -10px 0 30px 34px;
  opacity: 0.5;
  text-align: left;
  font-size: 15px;
`

type Props = {
  /** クラス名 */
  className?: string
}

/**
 * プラポリ同意チェックボックス直下テキスト
 * @constructor
 */
const PolicyUnderText = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <Wrapper className={`${className} txt-size-small`}>
      当社製品、サービスに関するご案内を入力メールアドレス宛に
      <br />
      お送りする可能性があります。
    </Wrapper>
  )
}

export default PolicyUnderText
