import { ChangeEvent, useState } from 'react'
import { IContactDownload } from '../types/Contact'
import useKindName from './useKindName'
import { ManagementKindList } from '../types/ManagementKind'
import useApi from './useApi'
import StringUtil from '../utils/StringUtil'
import { SiteKindList, SiteKindType } from '../types/SiteKind'

const InitContact: IContactDownload = {
  company: '',
  email: '',
  name: '',
  tel: '',
  agree: false,
  managementType: '',
  parameter: '',
  siteKind: '',
}
type IOnChangeContact = (
  key: keyof IContactDownload
) => (
  e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
) => void

type IReturn = {
  /** 問い合わせ内容 */
  contact: IContactDownload
  /** エラーフラグ */
  isError: boolean
  /** ローディングフラグ */
  loading: boolean
  /** 終了フラグ */
  finish: boolean
  /** 問い合わせ変更時コールバック */
  onChangeContact: IOnChangeContact
  /** 資料ダウンロード */
  execDownload: (siteKind: SiteKindType) => void
}

/**
 * 資料ダウンロード関連のフック関数
 */
const useDownload = (): IReturn => {
  const [contact, setContact] = useState<IContactDownload>(InitContact)
  const [isError, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [finish, setFinish] = useState(false)
  const getKindName = useKindName(ManagementKindList)
  const getSiteKindName = useKindName(SiteKindList)
  const { execApi } = useApi()

  /** データ変更時処理 */
  const onChangeContact: IOnChangeContact = (key) => (e) => {
    let val: string | boolean = e.target.value
    if (key === 'tel') val = StringUtil.pickNumberHyphen(val)
    else if (key === 'agree' && e.target instanceof HTMLInputElement)
      val = e.target.checked
    setContact({ ...contact, [key]: val })
  }

  /**
   * ダウンロードボタンクリック時処理
   */
  const execDownload = (siteKind: SiteKindType) => {
    if (
      !contact.company ||
      !contact.name ||
      !contact.tel ||
      !contact.email ||
      !contact.managementType ||
      !StringUtil.checkFormatMail(contact.email) ||
      !contact.agree
    ) {
      setError(true)
      return
    }
    setLoading(true)
    const request = {
      ...contact,
      managementType: getKindName(contact.managementType),
      parameter: window.location.search,
      siteKind: getSiteKindName(siteKind),
    }

    execApi(
      '/dl2022',
      request,
      () => {
        setFinish(true)
      },
      () => {
        setLoading(false)
      }
    )
  }
  return {
    contact,
    isError,
    loading,
    finish,
    onChangeContact,
    execDownload,
  }
}

export default useDownload
