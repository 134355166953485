/**
 * 現在の勤怠管理方法
 */
export const ManagementKind = {
  NONE: '',
  /** Excel・紙を利用 */
  INTRODUCTION_METHOD: 'excel-paper',
  /** 他社のクラウドサービスを導入済み */
  DETAIL: 'other-cloud',
  /** パッケージソフトを導入済み */
  PRICE: 'package-soft',
  /** 自社システムで運用 */
  PARTNER: 'my-system',
  /** その他管理 */
  OTHER: 'other',
} as const

export type ManagementKindType =
  typeof ManagementKind[keyof typeof ManagementKind]

export const ManagementKindList: {
  name: string
  kind: ManagementKindType
}[] = [
  { name: 'Excel・紙を利用', kind: ManagementKind.INTRODUCTION_METHOD },
  { name: '他社のクラウドサービスを導入済み', kind: ManagementKind.DETAIL },
  { name: 'パッケージソフトを導入済み', kind: ManagementKind.PRICE },
  { name: '自社システムで運用', kind: ManagementKind.PARTNER },
  { name: 'その他管理', kind: ManagementKind.OTHER },
]
