import styled from '@emotion/styled'
import { navigate } from 'gatsby-link'
import React, { useEffect } from 'react'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import useDownload from '../../hooks/useDownload'
import BreakPoints from '../../styles/breakPoints'
import { ManagementKindList } from '../../types/ManagementKind'
import StringUtil from '../../utils/StringUtil'
import CommonCheckbox from '../atoms/commonCheckbox'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import OfficialCommonH3Set from '../molecules/officialCommonH3Set'
import { SiteKind } from '../../types/SiteKind'
import PolicyUnderText from '../atoms/policyUnderText'

const Wrapper = styled.section`
  width: 100%;

  .p-section-inner {
    display: flex;
    max-width: 1100px;
    padding: 60px 0;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;

    .url-err {
      color: #ff6969;
      text-align: center;
      width: 100%;
      font-weight: bold;
      margin: -20px 0 10px;
      border: solid 2px #ff6969;
      padding: 20px;
    }

    .p-section-download {
      width: 100%;
      display: flex;
      margin: 0 auto 60px;
      justify-content: center;

      .p-download-01,
      .p-download-02 {
        width: 100%;
        position: relative;
      }

      .intro-wrap {
        max-width: 480px;
        padding-top: 30px;
      }

      .p-download-01 {
        width: fit-content;
        margin: 0 50px;
      }

      .img-wrap {
        position: relative;
        width: 400px;
        height: 180px;
        margin-top: 30px;

        img {
          position: absolute;
          width: 60%;
          border: #707070 1px solid;
          box-shadow: #888 0 2px 5px;
        }

        img:nth-of-type(1) {
          top: 0;
          right: 0;
        }

        img:nth-of-type(2) {
          bottom: 0;
          left: 0;
        }
      }

      .p-download-02 {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        background: #ffffff;
        border-radius: 10px;
        padding: 30px;

        .inner {
          width: 100%;
        }

        .item-wrap {
          margin: 0 0 20px;

          &.name-wrap {
            display: flex;

            div {
              width: 50%;
              margin: 0;
            }
          }

          .label-area {
            display: flex;
            flex-wrap: wrap;
            padding: 0 0 2px;

            .txt-color-alert {
              margin: 0 5px;

              &.require {
                opacity: 0.6;
              }

              &.err {
                margin-left: auto;
                word-break: keep-all;
              }
            }
          }
        }
      }
    }

    .p-select {
      overflow: hidden;
      text-align: center;
      position: relative;
      border: solid 2px #ebebeb;
      background: #ffffff;
      width: 100%;
      min-height: 40px;
      border-radius: 8px;
      transition: 0.3s;

      &:hover {
        border-color: #3ec7b3;
      }

      &:before {
        position: absolute;
        top: 45%;
        right: 0.8em;
        width: 0;
        height: 0;
        padding: 0;
        content: '';
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #272d2c;
        pointer-events: none;
      }

      select {
        width: 100%;
        cursor: pointer;
        text-indent: 0.01px;
        text-overflow: ellipsis;
        border: none;
        outline: none;
        background: transparent none;
        box-shadow: none;
        -webkit-appearance: none;
        appearance: none;
        padding: 8px 38px 8px 8px;

        &::-ms-expand {
          display: none;
        }
      }
    }
  }

  ${BreakPoints.xLarge} {
    .p-section-inner {
      .p-section-download {
        .p-download-01 {
          .intro-wrap {
          }

          .img-wrap {
            width: 300px;
            height: 135px;
          }
        }
      }
    }
  }

  ${BreakPoints.large} {
    .p-section-inner {
      .p-section-download {
        .p-download-01 {
          display: none;
        }
      }
    }
  }
`

/**
 * サービスサイト資料ダウンロード-入力画面
 * @constructor
 */
const OfficialLowDownload01 = () => {
  const {
    contact,
    isError,
    finish,
    loading,
    onChangeContact: onChange,
    execDownload: onClick,
  } = useDownload()
  const location = useLocation()
  const { expired } = queryString.parse(location.search)

  useEffect(() => {
    if (finish) {
      // https://developers.google.com/analytics/devguides/collection/gtagjs/events?hl=ja 参照
      window.gtag &&
        window.gtag('event', 'click', {
          event_category: 'download',
          event_label: 'd-official',
        })
      navigate('/download/thanks/')
    }
  }, [finish])

  return (
    <Wrapper>
      <div className="p-section-inner">
        {expired === '1' && (
          <p className="url-err">
            URLの有効期限が切れています。
            <br />
            誠に恐れ入りますが、下記フォームより再度お申し込みください。
          </p>
        )}
        <OfficialCommonH3Set
          description={
            '以下のフォームに必要事項を入力した上で、\n「資料ダウンロード」ボタンをクリックしてください。\nすぐに資料をダウンロードすることができます。'
          }
        />
        <div className="p-section-download">
          <div className="p-download-01">
            <div className="intro-wrap">
              <p>
                製品パンフレットや、
                <br />
                キンクラに関する情報をスライドにまとめた資料と
                <br />
                各機能の画面をご確認いただける資料をご用意しております。
                <br />
                ぜひお手元でご確認ください。
              </p>
              <div className="img-wrap">
                <img
                  src="/images/official/feature/imgDownload02.png"
                  alt="キンクラ"
                />
                <img
                  src="/images/official/feature/imgDownload03.png"
                  alt="キンクラ"
                />
              </div>
            </div>
          </div>
          {/** 入力エリアここから */}
          <div className="p-download-02">
            <div className="inner">
              <div className="item-wrap">
                <p className="label-area">
                  <span className="txt-bold">会社名</span>
                  <span className="txt-color-alert txt-size-small require">
                    *必須
                  </span>
                  {isError && !contact.company && (
                    <span className="txt-color-alert txt-size-small err">
                      会社名を入力してください
                    </span>
                  )}
                </p>
                <input
                  value={contact.company}
                  placeholder="○○株式会社"
                  onChange={onChange('company')}
                />
              </div>
              <div className="item-wrap">
                <p className="label-area">
                  <span className="txt-bold">ご担当者名</span>
                  <span className="txt-color-alert txt-size-small require">
                    *必須
                  </span>
                  {isError && !contact.name && (
                    <span className="txt-color-alert txt-size-small err">
                      氏名を入力してください
                    </span>
                  )}
                </p>
                <input
                  value={contact.name}
                  placeholder="田中太郎"
                  onChange={onChange('name')}
                />
              </div>
              <div className="item-wrap">
                <p className="label-area">
                  <span className="txt-bold">メールアドレス</span>
                  <span className="txt-color-alert txt-size-small require">
                    *必須
                  </span>
                  {isError && !contact.email && (
                    <span className="txt-color-alert txt-size-small err">
                      メールアドレスを入力してください
                    </span>
                  )}
                  {isError &&
                    contact.email &&
                    !StringUtil.checkFormatMail(contact.email) && (
                      <span className="txt-color-alert txt-size-small err">
                        メールアドレスの形式が間違っています
                      </span>
                    )}
                </p>
                <input
                  value={contact.email}
                  placeholder="info@sample.jp"
                  onChange={onChange('email')}
                />
              </div>
              <div className="item-wrap">
                <p className="label-area">
                  <span className="txt-bold">電話番号</span>
                  <span className="txt-color-alert txt-size-small require">
                    *必須
                  </span>
                  {isError && !contact.tel && (
                    <span className="txt-color-alert txt-size-small err">
                      電話番号を入力してください
                    </span>
                  )}
                </p>
                <input
                  value={contact.tel}
                  placeholder="0312345678"
                  onChange={onChange('tel')}
                />
              </div>
              <div className="item-wrap">
                <p className="label-area">
                  <span className="txt-bold">現在の勤怠管理方法</span>
                  <span className="txt-color-alert txt-size-small require">
                    *必須
                  </span>
                  {isError && !contact.managementType && (
                    <span className="txt-color-alert txt-size-small err">
                      現在の勤怠管理方法を選択してください
                    </span>
                  )}
                </p>
                <div className="p-select">
                  <select
                    required
                    value={contact.managementType}
                    onChange={onChange('managementType')}
                  >
                    <option value="" hidden>
                      選択してください
                    </option>
                    {ManagementKindList.map((val) => (
                      <option key={val.kind} value={val.kind}>
                        {val.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="txt-center">
                <CommonCheckbox
                  checked={contact.agree}
                  onChange={onChange('agree')}
                >
                  <a href="/policy/" target="_blank">
                    プライバシーポリシー
                  </a>
                  に同意する
                </CommonCheckbox>
                <PolicyUnderText />
                {isError && !contact.agree && (
                  <span className="txt-color-alert txt-size-small err">
                    プライバシーポリシーに同意してください。
                  </span>
                )}
              </div>
            </div>
            <OfficialCommonBtn
              size="large"
              level="normal"
              className="p-btn-download"
              onClick={() => onClick(SiteKind.PRODUCT)}
              disabled={loading}
            >
              資料ダウンロード
            </OfficialCommonBtn>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default OfficialLowDownload01
