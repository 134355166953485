/**
 * サイト種別
 */
export const SiteKind = {
  NONE: '',
  PRODUCT: 'product',
  LPA: 'lp-a',
  LPB: 'lp-b',
  EXPENSES: 'expenses',
} as const
export type SiteKindType = typeof SiteKind[keyof typeof SiteKind]

export const SiteKindList: {
  name: string
  kind: SiteKindType
}[] = [
  { name: '製品サイト', kind: SiteKind.PRODUCT },
  { name: 'LP-A', kind: SiteKind.LPA },
  { name: ' LP-B', kind: SiteKind.LPB },
  { name: ' 経費LP', kind: SiteKind.EXPENSES },
]
