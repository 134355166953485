import styled from '@emotion/styled'
import React, { ChangeEvent, ReactNode } from 'react'

const Wrapper = styled.div`
  padding: 8px 0;
  .atm-checkbox {
    display: flex;
    width: 100%;
    position: relative;
    cursor: pointer;
    user-select: none;
    padding: 0 0 10px 34px;
    input {
      display: none;
    }
    p {
      padding: 2px 0 0;
    }
  }
  .mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 26px;
    width: 26px;
    border: solid 2px #3abd9e;
    border-radius: 5px;
    box-sizing: border-box;
    &:after {
      content: '';
      position: absolute;
      border: solid #3abd9e;
      border-width: 0 2px 2px 0;
      left: 7px;
      top: 1px;
      width: 8px;
      height: 14px;
      transform: rotate(45deg);
      opacity: 0;
    }
  }
  .atm-checkbox {
    input:checked + .mark {
      background: transparent;
      border-color: #3abd9e;
    }
    input:checked + .mark:after {
      opacity: 1;
    }
  }
`
type IProps = {
  children: ReactNode
  checked?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

/**
 * 共通チェックボックスとラベルテキストのセット
 * @param props
 */
const CommonCheckbox = (props: IProps): JSX.Element => (
  <Wrapper>
    <label className="atm-checkbox txt-color-label">
      <input
        type="checkbox"
        checked={props.checked}
        onChange={props.onChange}
      />
      <span className="mark" />
      <p>{props.children}</p>
    </label>
  </Wrapper>
)

export default CommonCheckbox
